export const CLIENT_WITH_SCHEMA_1_MOCK = {
  client_id: 1334779,
  call_id: 1074,
  phone: "79998848670",
  crm_object_id: 1334778, // 1323759
  schema_id: 1,
  call_count: 2,
  message_text: "123",
  crm_object_stage: "123",
  client_name: "123",
  lead_url: "123",
}

export const CLIENT_WITH_SCHEMA_2_MOCK = {
  client_id: 1309462,
  call_id: 1074,
  phone: "79998848670",
  crm_object_id: 1308003,
  schema_id: 1188190,
  call_count: 2,
  message_text: "123",
  crm_object_stage: "123",
  client_name: "123",
  lead_url: "123",
}

export const FORM_MOCK = {
  forms: "home_crm_perezvon", //vstrechi_1 //dumaet
  proj: "string",
  operator: "string",
  msisdn: "string",
  dialer_id: "string",
  call_id: "string",
  stage: "string",
  name: "string",
}
